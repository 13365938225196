import { CalendarIcon, CurrencyDollarIcon, LocationMarkerIcon, SparklesIcon, ThumbUpIcon } from '@heroicons/react/outline';
import React, { useState } from 'react'
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Success from './Success';
import styled, { keyframes } from 'styled-components';
import { slideInUp, fadeInRight } from 'react-animations';

const slideInUpAnimation = keyframes`${slideInUp}`;
const fadeInRightAnimation = keyframes`${fadeInRight}`;

const SlideInUpDiv = styled.div`
  animation: 1s ${slideInUpAnimation};
`;

const FadeInRightDiv = styled.div`
  animation: 1s ${fadeInRightAnimation};
`;

export default function StepForm(props) {
    const [currentStep, setCurrentStep] = useState(1)

    const prevStep = (step) => {
        setCurrentStep(step - 1)
    }

    const nextStep = (step) => {
        setCurrentStep(step + 1)
    }

    const steps = () => {
      switch (currentStep) {
        case 1: 
          return (
            <Step1 
                nextStep={nextStep}
                updateFieldValue={props.updateFieldValue}
                owe_over_5k={props.dataArray.owe_over_5k}
                other={props.dataArray.other}
            />
        )
        case 2: 
          return (
            <Step2 
                prevStep={prevStep}
                nextStep={nextStep}
                updateFieldValue={props.updateFieldValue}
                total_debt={props.dataArray.total_debt}
            />
        )
        case 3: 
          return (
            <Step3 
              prevStep={prevStep}
              nextStep={nextStep}
              owe_over_5k={props.dataArray.owe_over_5k}
              updateFieldValue={props.updateFieldValue}
              state={props.dataArray.state}
              zip_code={props.dataArray.zip_code}
            />
        )
        case 4:
          return (
            <Step4 
                prevStep={prevStep}
                nextStep={nextStep}
                updateFieldValue={props.updateFieldValue}
                first_name={props.dataArray.first_name}
                last_name={props.dataArray.last_name}
                phone_number={props.dataArray.phone_number}
                email_address={props.dataArray.email_address}
                owe_over_5k={props.dataArray.owe_over_5k}
            />
        )
        case 5: 
          return (
            <Step5 
                prevStep={prevStep}
                nextStep={nextStep}
                updateFieldValue={props.updateFieldValue}
                additional={props.dataArray.additional}
                agree={props.dataArray.agree}
                dataArray={props.dataArray}
                did={props.did}
                source={props.source}
                setSuccess={props.setSuccess}
            />
        )
        case 6: 
          return (
            <Success did={props.did}/>
        )
        // never forget the default case, otherwise VS code would be mad!
        default: 
           // do nothing
    }
    }

    // const bottomRef = useRef(null);

    // useEffect(() => {
    //   // 👇️ scroll to bottom every time messages change
    //   bottomRef.current?.scrollIntoView({behavior: 'smooth'});
    // }, [currentStep > 2]);
  

    return (
      <div>
        <div className='flex items-center justify-center mb-5 -mt-16'>
          
        <div onClick={currentStep < 2 ? null : () => nextStep(0)} className={`${currentStep === 1 ? "bg-green-600 text-white drop-shadow-2xl" : "bg-gray-200 text-gray-400"}  h-14 w-14 rounded-full mx-1 justify-center`}>
          <CalendarIcon className={`h-14 w-14 p-2`}/>
        </div>
        <div onClick={currentStep < 3 ? null : () => nextStep(2)} className={`${currentStep === 2 ? "bg-green-600 text-white drop-shadow-2xl" : "bg-gray-200 text-gray-400"}  h-14 w-14 rounded-full mx-1 justify-center`}>
          <CurrencyDollarIcon className={`h-14 w-14 p-2`}/>
        </div>
        <div onClick={currentStep < 4 ? null : () => nextStep(3)} className={`${currentStep === 3 ? "bg-green-600 text-white drop-shadow-2xl" : "bg-gray-200 text-gray-400"}  h-14 w-14 rounded-full mx-1 justify-center`}>
          <LocationMarkerIcon className={`h-14 w-14 p-2`}/>
        </div>
        <div onClick={currentStep < 4 ? null : () => nextStep(4)} className={`${currentStep === 4 ? "bg-green-600 text-white drop-shadow-2xl" : "bg-gray-200 text-gray-400"} h-14 w-14 rounded-full mx-1 justify-center`}>
          <SparklesIcon className={`h-14 w-14 p-2`}/>
        </div>
        <div onClick={currentStep < 5 ? null : () => nextStep(5)} className={`${currentStep === 5 ? "bg-green-600 text-white drop-shadow-2xl" : "bg-gray-200 text-gray-400"}  h-14 w-14 rounded-full mx-1 justify-center`}>
          <ThumbUpIcon className={`h-14 w-14 p-2`}/>
        </div>
        </div>
        <p className='text-center text-gray-800 text-base uppercase font-extrabold tracking-widest mb-3'>Back tax help - Quiz</p>
        <h3 className="text-center text-xl md:text-2xl mb-3 leading-8 font-extrabold tracking-tight font-serif text-gray-800">
        Find Out If You <br/>Qualify For Back Tax Help
        </h3>
        <p className='text-center md:px-10 mx-auto text-gray-800 text-sm md:text-base italic mb-10'>See if you qualify for help with your back taxes in 45 seconds or less. No cost, no obligation.</p>
        <div className='bg-white p-0 md:p-2 rounded-md mx-2 my-5'>
        {currentStep === 2 && <SlideInUpDiv className='text-center text-sm italic top-5 text-green-600 font-semibold'>Looks good! Keep going!</SlideInUpDiv>}
        {currentStep === 3 && <FadeInRightDiv className='text-center text-sm italic top-5 text-green-600 font-semibold'>Got it! You&apos;re more than halfway done!</FadeInRightDiv>}
        {currentStep === 4 && <SlideInUpDiv className='text-center text-sm italic top-5 text-green-600 font-semibold'>You&apos;re almost done! Keep going!</SlideInUpDiv>}
        {currentStep === 5 && <FadeInRightDiv className='text-center text-sm italic top-5 text-green-600 font-semibold'>You&apos;re on the last step!</FadeInRightDiv>}
          { steps() }
        </div>
        
      </div>
    )
  
}