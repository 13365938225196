import React, { useState } from 'react'
import { XCircleIcon, ReplyIcon } from '@heroicons/react/outline';
import JSConfetti from 'js-confetti'

export default function Step4({ prevStep, nextStep, owe_over_5k, updateFieldValue, ...FormFields }) {

    const validZipCode = new RegExp('^[0-9]{5}(?:-[0-9]{4})?$')

    const jsConfetti = new JSConfetti()

    const errorObj = Object.keys(FormFields).reduce((prev, curr) => (prev[curr] = false, prev), {})

    //create an object of key value pair set to false each with a key === names of all basic form field keys
    const [errors, setErrors] = useState({ ...errorObj })

    const validFormFields = () => {
        let updateErrorObj = { ...errors };
        Object.entries(FormFields).some(([key, value]) => {
            if (key === "state") {
                updateErrorObj[key] = false
            } else if (!validZipCode.test(value) || !value) {
                updateErrorObj[key] = true;
                // console.log("error", key, value)
            } else {
                updateErrorObj[key] = false
            }
        })
        setErrors(updateErrorObj)
        return (Object.values(updateErrorObj).includes(true)) ? false : true
    }

    const handleValue = () => {
        if (validFormFields()) {
            // if no errors...
            try {
                nextStep(3)
                jsConfetti.addConfetti()
                //post to a db
            } catch (error) {
                console.error('error', error);
            } finally {
            }
        }
    }

    return (
        <div id="step3" className="text-center md:text-left col-span-6 sm:col-span-4 p-4">
            {Object.entries(FormFields).map(([key, value]) =>
                <div key={key}>
                    {key === 'state' ?
                        <div className="col-span-6 sm:col-span-4 mb-6">
                            <label htmlFor="state" className="block text-sm font-medium capitalize text-gray-700">
                                {key.replace(/_/g, ' ')}
                            </label>
                            <select
                                id="state"
                                name="state"
                                defaultValue="FL"
                                onChange={(event) => updateFieldValue("state", event.target.value)}
                                className="mt-1 h-10 block text-gray-700 w-full py-2 px-3 border border-gray-300 bg-white rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            >
                                <option value="AL">Alabama - AL</option>
                                <option value="AK">Alaska - AK</option>
                                <option value="AZ">Arizona - AZ</option>
                                <option value="AR">Arkansas - AR</option>
                                <option value="CA">California - CA</option>
                                <option value="CO">Colorado - CO</option>
                                <option value="CT">Connecticut - CT</option>
                                <option value="DE">Delaware - DE</option>
                                <option value="FL">Florida - FL</option>
                                <option value="GA">Georgia - GA</option>
                                <option value="HI">Hawaii - HI</option>
                                <option value="ID">Idaho - ID</option>
                                <option value="IL">Illinois - IL</option>
                                <option value="IN">Indiana - IN</option>
                                <option value="IA">Iowa - IA</option>
                                <option value="KS">Kansas - KS</option>
                                <option value="KY">Kentucky - KY</option>
                                <option value="LA">Louisiana - LA</option>
                                <option value="ME">Maine - ME</option>
                                <option value="MD">Maryland - MD</option>
                                <option value="MA">Massachusetts - MA</option>
                                <option value="MI">Michigan - MI</option>
                                <option value="MN">Minnesota - MN</option>
                                <option value="MS">Mississippi - MS</option>
                                <option value="MO">Missouri - MO</option>
                                <option value="MT">Montana - MT</option>
                                <option value="NE">Nebraska - NE</option>
                                <option value="NV">Nevada - NV</option>
                                <option value="NH">New Hampshire - NH</option>
                                <option value="NJ">New Jersey - NJ</option>
                                <option value="NM">New Mexico - NM</option>
                                <option value="NY">New York - NY</option>
                                <option value="NC">North Carolina - NC</option>
                                <option value="ND">North Dakota - ND</option>
                                <option value="OH">Ohio - OH</option>
                                <option value="OK">Oklahoma - OK</option>
                                <option value="OR">Oregon - OR</option>
                                <option value="PA">Pennsylvania - PA</option>
                                <option value="RI">Rhode Island - RI</option>
                                <option value="SC">South Carolina - SC</option>
                                <option value="SD">South Dakota - SD</option>
                                <option value="TN">Tennessee - TN</option>
                                <option value="TX">Texas - TX</option>
                                <option value="UT">Utah - UT</option>
                                <option value="VT">Vermont - VT</option>
                                <option value="VA">Virginia - VA</option>
                                <option value="WA">Washington - WA</option>
                                <option value="DC">Washington D.C. - DC</option>
                                <option value="WV">West Virginia - WV</option>
                                <option value="WI">Wisconsin - WI</option>
                                <option value="WY">Wyoming - WY</option>
                            </select>
                        </div>
                        :
                        <div className="col-span-6 sm:col-span-4 mb-6">
                            <label htmlFor={`${value}-${key}`} className="block text-sm font-medium text-gray-700 capitalize">
                                ZIP code
                            </label>
                            <input
                                type="text"
                                name={key}
                                maxLength="5"
                                id={`${value}-${key}`}
                                defaultValue={`${value}`}
                                onChange={(event) => updateFieldValue(event.target.name, event.target.value)}
                                className="p-4 mt-1 h-10 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                            />
                            {errors[key] &&
                                <p className="mt-2 mb-0 text-xs text-red-500 flex">
                                    <XCircleIcon className="h-4 w-4 mr-1" /> Please enter a valid ZIP code (U.S. postal code).
                                </p>
                            }
                        </div>
                    }
                </div>
            )}

            <div className="flex flex-col md:flex-row mt-10 justify-center md:justify-end">
                <button
                    //add onclick to save all pattern yarn info and post
                    onClick={() => prevStep(owe_over_5k === true ? 3 : 2)}
                    className="
            inline-flex 
            items-center 
            justify-center  
            rounded-md
            mr-3
            md:my-2
            mt-5
            text-sm 
            md:w-fit
order-last
md:order-first
            font-medium 
            text-blue-500
            hover:text-blue-800
            focus:outline-none 
            focus:ring-2 
            focus:ring-offset-2 
            focus:ring-blue-500"
                >
                    <ReplyIcon className='h-3 w-3 mr-2' /> Back
                </button>
                <button
                    onClick={() => handleValue()}
                    className="
            inline-flex 
            items-center 
            justify-center  
            rounded-md
            py-2 
            px-4 
            w-full 
            h-12
            md:w-fit
            shadow-sm 
            text-sm 
            font-medium 
            text-white bg-blue-500 hover:bg-blue-800
            focus:outline-none 
            focus:ring-2 
            focus:ring-offset-2 
            focus:ring-blue-500"
                >
                    Next
                </button>
            </div>
        </div>
    );
}