import { CheckCircleIcon, PhoneIcon } from '@heroicons/react/outline';
import React, {useEffect, useRef} from 'react'

export default function Success(props) {
  const topRef = useRef(null);

  useEffect(() => {
      // 👇️ scroll to top for this step
      topRef.current?.scrollIntoView({ behavior: 'smooth', block:'center' });
  });

  const today = new Date()
  const isWeekend = (today.getDay() === 6) || (today.getDay() === 0);
  const isOpen = (today.getHours() >= 6) && (today.getHours() <= 18);

  const online = () => {
    //if weekend
    if (isWeekend) {
      return null
    } else {
      //if its 6am-6pm
      if (isOpen) {
        return (
          <div className='flex items-center justify-center'>
            <div className='relative'>
              <span className="animate-ping absolute inline-flex rounded-full h-3 w-3 bg-white mr-1"></span>
              <span className="absolute inline-flex rounded-full h-3 w-3 bg-white mr-1"></span>
            </div>
            <div className='ml-5 mt-2 text-base'>Active</div>
          </div>
        )
      } else {
        return null
      }
    }
  }

  return (
    <div ref={topRef} className='text-gray-800 text-center text-base lg:px-4 xl:px-10' id="success">
      <CheckCircleIcon className='mx-auto mb-4 h-16 w-16 text-green-600' />
      <p className='text-lg font-bold mb-4'><b>Congratulations</b> on taking the first step towards tax debt resolution!</p>
      <p className='mb-5'>One of our representatives will be in contact shortly to confirm your information and discuss the next steps in your tax debt forgiveness process.</p>
      {online && <div className='my-10 bg-green-600 p-4 shadow'>
        <p className='text-white text-lg font-semibold'>Want to get started A.S.A.P.?</p>
        <p className='text-white font-black uppercase tracking-widest mt-4 text-2xl'>Call now</p>
        <a href={`tel:${props.did}`}
          id="successPhoneButton"
          className="group flex items-center mx-auto justify-center my-5 w-full md:w-fit rounded-sm drop-shadow-md hover:drop-shadow-2xl p-4 lg:px-10 md:py-5 bg-white text-gray-900 font-extrabold text-xl xl:text-2xl uppercase tracking-widest">
          <PhoneIcon className='transition transform  ease-out group-hover:ease-in motion-reduce:transition-none motion-reduce:hover:transform-none group-hover:-translate-x-2 mr-2 w-5 xl:h-6 h-5 xl:w-6' /> {props.did}
        </a>
        <p className='text-white italic'>6am - 6pm Pacific, M-F</p>
      </div>}
    </div>
  );
}
