import React, { useState } from 'react'
import { XCircleIcon, ReplyIcon, CheckCircleIcon, XIcon } from '@heroicons/react/outline';

export default function Step1({ nextStep, updateFieldValue, other, owe_over_5k }) {

    const [altPath, setAltPath] = useState(false)
    const [otherPath, ShowOtherPath] = useState(false)
    const [checkPath, ShowCheckPath] = useState(false)


    const [optionErr, setOptionErr] = useState(false);

    // console.log("other value", other)
    // console.log(optionErr)

    const handleValue = () => {
        if (other) {
            // if no errors...
            try {
                setOptionErr(false)
                //skip the slider on step 2
                nextStep(2)

            } catch (error) {
                console.error('error', error);
            } finally {
            }
        } else {
            setOptionErr(true)
        }
    }

    const handleNextStep = (field) => {
        if (field === 'owe_over_5k') {
            updateFieldValue('owe_over_5k', true);
            nextStep(1)
        } else {
            nextStep(1)
        }
    }

    const handleAltPath = (value) => {
        setAltPath(value)
        updateFieldValue('owe_over_5k', false);
    }



    return (
        <div id="step1">
            {altPath ?
                (checkPath ?
                    <p className='p-5 text-center'>If you have questions about stimulus checks, please <b>contact the IRS directly</b> for more information. <br /><br /> <i>Thank you</i>.</p>
                    :
                    (otherPath ?
                        <div className='text-center col-span-6 sm:col-span-4 md:p-4'>
                            <label htmlFor="other" className="mb-5 block text-md md:text-xl font-medium text-gray-800">
                                What is your current tax problem?
                            </label>
                            <div className="mt-1">
                                <textarea
                                    id="other"
                                    name="other"
                                    rows={3}
                                    maxLength={100}
                                    className="p-4 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                                    onChange={(event) => updateFieldValue('other', event.target.value)}
                                    defaultValue={other}
                                />
                            </div>
                            <p className="my-2 text-sm text-gray-500">
                                Brief description of your tax-related problem.
                            </p>
                            {optionErr &&
                                <p className="mt-2 mb-0 text-xs text-red-500 flex">
                                    <XCircleIcon className="h-4 w-4 mr-1" /> Please enter a response.
                                </p>
                            }
                            <div className="flex flex-col md:flex-row mt-10 justify-center md:justify-end">
                                <button
                                    //add onclick to save all pattern yarn info and post
                                    onClick={() => handleAltPath(false)}
                                    className="
                                                inline-flex 
                                                items-center 
                                                justify-center  
                                                rounded-md
                                                mr-3
                                                md:my-2
                                                mt-5
                                                text-sm 
                                                md:w-fit
            order-last
            md:order-first
                                                font-medium 
                                                text-blue-500
                                                hover:text-blue-800
                                                focus:outline-none 
                                                focus:ring-2 
                                                focus:ring-offset-2 
                                                focus:ring-blue-500"
                                >
                                    <ReplyIcon className='h-3 w-3 mr-2' /> Back
                                </button>
                                <button
                                    onClick={(event) => handleValue(event)}
                                    className="
                                                inline-flex 
                                                items-center 
                                                justify-center  
                                                rounded-md
                                                py-2 
                                                px-4 
                                                w-full 
                                                md:w-fit
                                                shadow-sm 
                                                h-12
                                                text-sm 
                                                font-medium 
                                                text-white bg-blue-500 hover:bg-blue-800
                                                focus:outline-none 
                                                focus:ring-2 
                                                focus:ring-offset-2 
                                                focus:ring-blue-500"
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                        :
                        <div className="text-center col-span-6 sm:col-span-4 p-4">
                            <div>
                                <label htmlFor={`other`} className="mb-5 block text-md md:text-xl font-medium text-gray-800">
                                    What type of tax-related help do you need?
                                </label>
                                <div className='flex flex-col'>
                                    <button onClick={() => ShowCheckPath(true)} className="hover:translate-y-0.5 hover:drop-shadow-sm drop-shadow-lg my-2 inline-flex items-center justify-center px-5 py-3 text-base font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700">
                                        Stimulus Check
                                    </button>
                                    <button onClick={() => ShowOtherPath(true)} className="hover:translate-y-0.5 hover:drop-shadow-sm drop-shadow-lg my-2 inline-flex items-center justify-center px-5 py-3 text-base font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700">
                                        Other
                                    </button>
                                </div>
                                <div className="mt-10 text-right">
                                    <button
                                        //add onclick to save all pattern yarn info and post
                                        onClick={() => handleAltPath(false)}
                                        className="
                                    inline-flex 
                                    items-center 
                                    justify-center  
                                    mr-3
                                    text-sm 
                                    font-medium 
                                    rounded-md 
                                    text-blue-500
                                    hover:text-blue-800
                                    focus:outline-none 
                                    focus:ring-2 
                                    focus:ring-offset-2 
                                    focus:ring-indigo-500"
                                    >
                                        <ReplyIcon className='h-3 w-3 mr-2' /> Back
                                    </button>
                                </div>
                            </div>
                        </div>)
                )
                :

                <div>

                    <div className="text-center col-span-6 sm:col-span-4 md:p-4">
                        <label htmlFor={`owe_over_5k`} className="mb-5 block text-md md:text-xl font-medium text-gray-800">
                            Do you owe over $5K to the IRS in back taxes?
                        </label>
                        <div className='flex flex-col'>
                            <button
                                onClick={() => handleNextStep('owe_over_5k')}
                                className="hover:translate-y-0.5 hover:drop-shadow-sm drop-shadow-lg my-2 inline-flex items-center justify-center px-5 py-3 text-base font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700">
                                <CheckCircleIcon className="h-4 w-4 mr-1" />Yes, I owe $5k +
                            </button>
                            <button onClick={() => handleAltPath(true)} className="hover:translate-y-0.5 hover:drop-shadow-sm drop-shadow-lg my-2 inline-flex items-center justify-center px-5 py-3 text-base font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700">
                                <XIcon className="h-4 w-4 mr-1" />No, I owe less than $5k
                            </button>
                            <button onClick={() => handleAltPath(true)} className="hover:translate-y-0.5 hover:drop-shadow-sm drop-shadow-lg my-2 inline-flex items-center justify-center px-5 py-3 text-base font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700">
                                <XIcon className="h-4 w-4 mr-1" />No, I don't owe the IRS
                            </button>
                        </div>
                    </div>
                </div>
            }
        </div>
    );

}