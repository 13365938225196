import { ArrowCircleRightIcon, CheckCircleIcon, CheckIcon, PhoneIcon, XIcon } from '@heroicons/react/outline';
import React, { useState, useEffect, useRef } from 'react'
import StepForm from './StepForm';
import Fade from 'react-reveal/Fade'
import Zoom from 'react-reveal/Zoom'
import BackButtonModal from './BackButtonModal';
import ScrollModal from './ScrollModal';

export default function LandingPage(props) {

    const [dataArray, setDataArray] = useState({
        first_name: "",
        last_name: "",
        email_address: "",
        phone_number: "",
        state: "FL",
        zip_code: "",
        agree: "",
        years_unFiled: "",
        total_debt: 10000,
        owe_over_5k: "",
        additional: "",
        other: "",
        agree: true,
    })

    // console.log("data", dataArray)

    const [back, setBack] = useState(false)
    const [backOnce, setBackOnce] = useState(false)
    const [scroll, setScroll] = useState(false)
    const [success, setSuccess] = useState(false)

    const updateFieldValue = (name, value) => {
        let newDataArray = { ...dataArray };
        newDataArray[name] = value
        setDataArray(newDataArray)
    }

    const today = new Date()
    const isWeekend = (today.getDay() === 6) || (today.getDay() === 0);
    const isOpen = (today.getHours() >= 6) && (today.getHours() <= 18);

    const online = () => {
        //if weekend
        if (isWeekend) {
            return null
        } else {
            //if its 6am-6pm
            if (isOpen) {
                return (
                    <div className='flex items-center justify-center'>

                        <div className='relative'>
                            <span className="animate-ping absolute inline-flex rounded-full h-3 w-3 bg-green-500 mr-1"></span>
                            <span className="absolute inline-flex rounded-full h-3 w-3 bg-green-500 mr-1"></span>
                        </div>
                        <div className='ml-5 mt-2 text-base'>Active</div>
                    </div>
                )
            } else {
                return null
            }
        }
    }

    //for the back button/leaving
    useEffect(() => {
        var mouseY = 0;
        var topValue = 0;
        if (backOnce === true) {
            setBack(false)
            const timer = setTimeout(() => {
                setScroll(false)
                setBackOnce(false)
            }, 60000); //60 seconds
            return () => clearTimeout(timer);
        } else {
            window.addEventListener("mouseout", function (e) {
                mouseY = e.clientY;
                if (mouseY < topValue) {
                    setBack(true)
                    setScroll(false)
                }
            },
                false);
        }
    }, [backOnce])

    //for scroll
    // useEffect(() => {
    //     // window.addEventListener("scroll", function (e) {
    //     //     if ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight) {
    //     //         setScroll(true)
    //     //         setBack(false)
    //     //     }
    //     // });
    //     setTimeout(() => {
    //         setScroll(true)
    //         setBack(false)
    //     }, 3000);
    // })

    useEffect(() => {
        const timer = setTimeout(() => {
            setScroll(true)
            setBack(false)
        }, 30000); //30 seconds
        return () => clearTimeout(timer);
    }, []);

    return (
        <div>
            {
                !isWeekend && isOpen && back && !success &&
                <div>
                    <BackButtonModal did={props.did} backOnce={backOnce} setBackOnce={setBackOnce} open={back} setOpen={setBack} />
                </div>
            }
            {
                !isWeekend && isOpen && scroll && !success &&
                <div>
                    <ScrollModal did={props.did} open={scroll} setOpen={setScroll} />
                </div>
            }
            <div className='banner'>
                <div className='flex pt-5 md:mx-4 md:pt-1 items-center justify-center md:justify-between font-serif text-2xl text-white'>
                    <div className='flex items-center'><img src="/logo_light.png" alt="tax debt united" className='h-8 md:h-12 mr-2' /> Tax Debt United</div>
                    <div className='hidden md:block'>
                        <a href={`tel:${props.did}`} id="buttonNavPhone" className="w-fit mx-auto my-2 flex items-center justify-center px-5 text-white hover:text-black hover:bg-white py-3 text-xl font-extrabold rounded-full">
                            <PhoneIcon className='h-5 w-5 mr-1' />
                            <span>{props.did}</span>
                        </a>
                    </div>
                </div>
                <div className='mt-1 py-2 flex flex-col md:flex-row justify-center items-center text-white' style={{ backgroundColor: "rgb(0, 36, 74, 0.5)" }}>
                    <span className='text-sm italic'>in partnership with</span>
                    <div className='flex items-center font-bold text-xl wendy uppercase tracking-widest'>
                        <img
                            className="block mt-2 mx-2 h-8 w-auto not-italic"
                            src="/ovation-logo.png"
                            alt="Ovation"
                        /> Ovation Tax Group
                    </div>
                </div>
                <div id="quiz" className='pt-0 pb-10 md:pb-10 lg:pb-28'>
                    <div className='grid lg:grid-cols-12 backdrop-blur-sm bg-white/30'>
                        <div className='col-start-1 col-end-8 p-5 md:p-10'>
                            <h1 className="text-center my-1 max-w-2xl text-xl tracking-tight mx-auto font-black text-gray-900 md:text-4xl lg:text-4xl">
                                <span className="block">Looking to resolve</span>
                                <Zoom>
                                    <span className="block italic text-4xl lg:text-7xl font-serif text-white underline-offset-8">BACK TAXES</span>
                                </Zoom>
                                <span className="block"> owed to the IRS?</span>
                            </h1>
                            <p className='hidden md:block px-5 text-center mx-auto lg:mx-0 text-gray-900 text-md font-semibold'>Take the <span className="uppercase font-black text-gray-900 mx-1 tracking-widest">45 sec. quiz</span> to see if you qualify <span className='underline'>for tax help</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='lg:grid lg:grid-cols-12 gap-4 p-1 md:px-5 lg:px-10 lg:my-10'>
                <div className='hidden lg:block lg:col-span-7 lg:px-10'>
                    <div className='text-center mb-10 font-semibold text-lg text-gray-900'>
                        <p className='flex items-center justify-start'> <CheckCircleIcon className='text-green-500 h-5 w-5 mr-1' /> Quick and easy</p>
                        <p className='flex items-center justify-start'> <CheckCircleIcon className='text-green-500 h-5 w-5 mr-1' /> Good and fast service</p>
                        <p className='flex items-center justify-start'> <CheckCircleIcon className='text-green-500 h-5 w-5 mr-1' /> Very professional and knowledgeable</p>
                    </div>
                    <div>
                        <p className="mt-3 text-base text-gray-600 sm:mt-5 sm:text-lg sm:mx-auto lg:mt-5 lg:text-xl lg:mx-0">If you owe at least $5,000 in back taxes... and are ready to climb out of the "tax pit", please pay attention to the information on this page. </p>
                        <p className="mt-20 text-2xl mb-10 leading-8 font-extrabold tracking-tight text-gray-800 md:text-4xl">There is good news</p>
                        <p className="mt-3 text-base text-gray-600 sm:mt-5 sm:text-lg sm:mx-auto lg:mt-5 lg:text-xl lg:mx-0">Debt settlement allows the creditor and the debtor (a.k.a. you) to negotiate your debt amount so you can pay less than the total amount owed.</p>
                    </div>
                </div>
                <Fade up>
                    <div className='z-30 lg:col-span-5 lg:-mt-30 sticky'>
                        <div className='lg:-mt-96 '>
                            {/* Step Form */}
                            <div className=''>
                                <div className='drop-shadow-xl bg-white lg:w-fit mx-auto pb-1 md:pb-0 pt-10 px-2 md:px-5 rounded-lg shadow'>
                                    <StepForm
                                        dataArray={dataArray}
                                        updateFieldValue={updateFieldValue}
                                        did={props.did}
                                        source={props.source}
                                        setSuccess={setSuccess}
                                    />
                                </div>
                                <div className='group mt-10 text-center'>
                                    <h1 className='font-semibold text-gray-700'>Looking to get started immediately?</h1>
                                    <p className='italic text-gray-500'>6am - 6pm Pacific, M-F</p>
                                    <a href={`tel:${props.did}`} id="button1Phone" className="w-fit mx-auto my-2 flex items-center justify-center px-5 bg-green-600 hover:bg-green-800 text-white py-3 text-xl font-extrabold rounded-full">
                                        <span>{props.did}</span>
                                    </a>

                                    <p className='mb-10 italic'>
                                        {online()}
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
                <div className='block lg:hidden lg:col-span-7 lg:px-10'>
                    <div>
                        <p className="mt-3 text-base text-gray-600 sm:mt-5 sm:text-lg sm:mx-auto lg:mt-5 lg:text-xl lg:mx-0">If you owe at least $5,000 in back taxes... and are ready to climb out of the "tax pit", please pay attention to the information on this page. </p>
                        <p className="mt-20 text-2xl mb-10 leading-8 font-extrabold tracking-tight text-gray-800 md:text-4xl">There is good news</p>
                        <p className="mt-3 text-base text-gray-600 sm:mt-5 sm:text-lg sm:mx-auto lg:mt-5 lg:text-xl lg:mx-0">Debt settlement allows the creditor and the debtor (a.k.a. you) to negotiate your debt amount so you can pay less than the total amount owed.</p>
                    </div>
                </div>


                <div className='col-span-12 lg:px-10 lg:mb-10 text-2xl'>
                    <p className='mt-10 text-2xl mb-10 leading-8 font-extrabold tracking-tight text-gray-800 md:text-4xl'>Yes, you read that right</p>
                    <p className="mt-3 text-base text-gray-600 sm:mt-5 sm:text-lg sm:mx-auto lg:mt-5 lg:text-xl lg:mx-0"> Debt settlement can reduce the total amount you own while still fulfilling your total debt. This means you can pay less while still settling all of your tax debt.</p>
                    <p className='mt-20 text-2xl mb-10 leading-8 font-extrabold tracking-tight text-gray-800 md:text-4xl'>What does this mean?</p>
                    <p className="mt-3 text-base text-gray-600 sm:mt-5 sm:text-lg sm:mx-auto lg:mt-5 lg:text-xl lg:mx-0">Your back taxes can finally be resolved and you don't have to worry about your children and grandchildren being left with your unpaid back taxes.</p>
                    <p className='mt-20 text-2xl mb-10 leading-8 font-extrabold tracking-tight text-gray-800 md:text-4xl'>But that's not all...</p>
                    <p className="mt-3 text-base text-gray-600 sm:mt-5 sm:text-lg sm:mx-auto lg:mt-5 lg:text-xl lg:mx-0">We partner with reputable, national finance companies who have already helped thousands of Americans navigate confusing tax law and the debt settlement process.</p>
                    <p className="mt-3 text-base text-gray-600 sm:mt-5 sm:text-lg sm:mx-auto lg:mt-5 lg:text-xl lg:mx-0">You could be making huge mistakes trying to settle your debt alone when there are qualified professionals waiting to help you figure out how much you really owe the IRS. </p>

                    <p className="mt-20 text-xl mb-10 leading-8 font-extrabold tracking-tight text-gray-800 md:text-4xl">How do I know if I qualify for help with back taxes?</p>
                    <p className="mb-4 grid gap-1 md:gap-2 grid-cols-12">
                        <CheckIcon className='col-span-2 md:col-span-1 text-white bg-green-600 rounded-full p-1 mr-2 h-8 w-8' />
                        <span className='col-span-10 md:col-span-11'><b>Owe at least $5,000 or more</b> in back taxes</span>
                    </p>
                    <p className="mb-4 grid gap-2 grid-cols-12">
                        <CheckIcon className='col-span-2 md:col-span-1 text-white bg-green-600 rounded-full p-1 mr-2 h-8 w-8' />
                        <span className='col-span-10 md:col-span-11'>Live in a <b>qualified zip code</b></span>
                    </p>
                    <p className="mb-4 grid gap-2 grid-cols-12">
                        <CheckIcon className='col-span-2 md:col-span-1 text-white bg-green-600 rounded-full p-1 mr-2 h-8 w-8' />
                        <span className='col-span-10 md:col-span-11'>Want <b>step-by-step instructions</b> from a licensed tax debt professional</span>
                    </p>
                    <p className="mt-3 text-base text-gray-600 sm:mt-5 sm:text-lg sm:mx-auto lg:mt-5 lg:text-xl lg:mx-0">
                        Then you may qualify for a tax debt consultation from a professionally licensed tax advisor.
                    </p>
                    <a href="#quiz" id="quizButton" className="text-lg font-extrabold my-10 w-fit flex flex-wrap items-center underline md:no-underline rounded-md md:px-5 py-5 bg-transparent md:border-2 md:border-blue-500 md:hover:bg-blue-500 md:hover:text-white hover:text-blue-800 text-blue-500">
                        Take the <b className="font-extrabold mx-1 uppercase tracking-wide">45 sec Quiz</b> to see if you qualify <span className='mx-1 underline'>for back tax help</span> <ArrowCircleRightIcon className='hidden md:block ml-3 h-6 w-6' />
                    </a>

                    <p className="mt-20 text-2xl mb-10 leading-8 font-extrabold tracking-tight text-gray-800 md:text-4xl">But time is running out...</p>
                    <p className="mt-3 text-base text-gray-600 sm:mt-5 sm:text-lg sm:mx-auto lg:mt-5 lg:text-xl lg:mx-0">According to the IRS, if you do nothing to pay back your taxes, the IRS can legally steal up to 15% of your Social Security paycheck...<b className='text-gray-700'>money that was owed to you.</b></p>
                    <p className="mt-3 text-base text-gray-600 sm:mt-5 sm:text-lg sm:mx-auto lg:mt-5 lg:text-xl lg:mx-0">15% is a lot of money on a fixed income!</p>
                    <p className="mt-3 text-base text-gray-600 sm:mt-5 sm:text-lg sm:mx-auto lg:mt-5 lg:text-xl lg:mx-0">If this makes you angry and want to make sure the IRS isn't taking 15% of your Social Security, speak to a US-based company today who can give you sound tax advice based on their legal certificates and credentials.</p>
                </div>
            </div>

            <div className='drop-shadow-2xl mx-auto text-center mt-10 p-1 py-10 px-1 shadow banner'>
                <p className='text-center text-white text-base uppercase font-extrabold tracking-widest mb-3'>Ready to start today?</p>
                <h3 className="text-center text-2xl mb-3 leading-8 font-extrabold tracking-tight text-gray-100 md:text-4xl">
                    Get <span className='underline'>Back Tax Help</span> ASAP
                </h3>
                <p className='text-center lg:px-10 mx-auto text-gray-100 text-lg italic mb-10'>Get in contact with one of our tax partners immediately.</p>
                <div className='bg-white w-fit mx-auto p-5 rounded-md my-5'>
                    <p className="text-lg text-gray-800 font-bold sm:mt-5 sm:mx-auto lg:mt-5 lg:mx-0 text-center">Our partners operations hours: <br /> <span className="font-normal italic">6:00 am - 6:00 pm Pacific, Monday - Friday</span></p>
                    <a href={`tel:${props.did}`} id="button2Phone" className="mt-5 hover:translate-y-0.5 hover:drop-shadow-sm drop-shadow-lg my-2 inline-flex items-center justify-center px-5 py-3 text-xl font-medium rounded-md text-white bg-blue-600 hover:bg-green-500">
                        <span>{props.did}</span>
                    </a>
                    <p className='mt-2 italic text-xs text-center'>
                        {online(new Date())}
                    </p>
                </div>
            </div>
        </div>
    );
}