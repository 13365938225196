import React, { Fragment, useRef} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { PhoneIcon, XIcon } from '@heroicons/react/outline'
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { pulse } from 'react-animations';

const pulseAnimation = keyframes`${pulse}`;


const PulseDiv = styled.div`
  animation: 1s ${pulseAnimation};
`;

export default function BackButtonModal(props) {

    const cancelButtonRef = useRef(null)

    const today = new Date()
    const isWeekend = (today.getDay() === 6) || (today.getDay() === 0);
    const isOpen = (today.getHours() >= 6) && (today.getHours() <= 18);

    const online = () => {
        //if weekend
        if (isWeekend) {
            return null
        } else {
            //if its 6am-6pm
            if (isOpen) {
                return (
                    <div className='flex items-center justify-center'>

                        <div className='relative'>
                            <span className="animate-ping absolute inline-flex rounded-full h-3 w-3 bg-green-500 mr-1"></span>
                            <span className="absolute inline-flex rounded-full h-3 w-3 bg-green-500 mr-1"></span>
                        </div>
                        <div className='ml-5 mt-2 text-base'>Active</div>
                    </div>
                )
            } else {
                return null
            }
        }
    }

    const closeTab = () => {
        props.setOpen(false)
        props.setBackOnce(true)
    };

    return (

        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className={`relative z-50 ${props.backOnce && "opacity-0"}`} initialFocus={cancelButtonRef} onClose={props.setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-90 transition-opacity w-screen" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto w-screen">
                    <div className="flex min-h-full items-center justify-center p-2 md:p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden  shadow-xl transition-all max-w-4xl">

                                <div>
                                    <div className="">
                                        <div className='py-10 text-white rounded-t-lg bg-blue-900 relative'>
                                            <div onClick={() => closeTab()} className="absolute right-1 top-1 h-10 w-10 rounded-full group p-1 bg-opacity-75 bg-blue-700 hover:bg-blue-600">
                                                <XIcon className='text-white group-hover:text-gray-400 h-8 w-8' />
                                            </div>
                                            <div className='my-auto text-center'>
                                                <div className='pb-5 flex items-center justify-center font-serif text-2xl text-white'>
                                                    <img src="/logo_light.png" alt="tax debt united" className='h-8 mr-2' /> Tax Debt United
                                                </div>
                                                <PulseDiv>
                                                    <Dialog.Title as="h3" className="mt-3 font-serif text-5xl md:text-6xl font-medium text-white">
                                                        Wait a minute!
                                                    </Dialog.Title>
                                                </PulseDiv>
                                                <h2 className="mt-10 text-xl md:text-3xl md:px-10 font-medium text-white leading-6">
                                                    What about your free back tax consultation?
                                                </h2>
                                                <div className='mt-5 flex flex-col justify-center items-center text-white'>
                                                    in partnership with
                                                    <div className='flex items-center font-bold text-xl wendy uppercase tracking-widest'>
                                                        <img
                                                            className="block mt-2 mx-2 h-8 w-auto"
                                                            src="/ovation-logo.png"
                                                            alt="Ovation"
                                                        />Ovation Tax Group
                                                    </div>
                                                </div>
                                                {/* <p className='mt-2 text-lg text-white max-w-xl mx-auto italic px-10'>Call now to instantly get connected with a qualified back tax expert.</p> */}
                                            </div>
                                        </div>
                                        <div className='bg-gradient-to-b from-white to-gray-200 rounded-b-lg p-5 md:p-10'>
                                        <p className='mb-5 font-bold  text-gray-600 text-lg text-center'>Call NOW! No cost, no obligation, learn if you qualify!</p>
                                            <a href={`tel:${props.did}`} id="BackModalPhoneButton" className='group my-5 md:my-0 w-full mx-auto text-white  bg-green-600 hover:bg-green-500 text-2xl tracking-widest cursor-pointer font-bold rounded-md uppercase flex items-center justify-center px-6 py-4'>
                                                <PhoneIcon className='transition transform  ease-out group-hover:ease-in motion-reduce:transition-none motion-reduce:hover:transform-none group-hover:-translate-x-2 mr-2 h-6 w-6' /> {props.did}
                                            </a>

                                            <p className='mt-5 italic text-gray-600'>6am - 6pm Pacific, M-F</p>
                                            <p className='italic text-gray-600'>
                                                {online()}
                                            </p>

                                        </div>
                                    </div>
                                    {/* <div onClick={() => closeTab()} className='bg-gray-300 group my-0 w-full mx-auto text-gray-900 hover:underline text-sm cursor-pointer font-bold rounded-b-lg uppercase flex items-center justify-center px-6 py-4'>
                                        <XIcon className='transition transform  ease-out group-hover:ease-in motion-reduce:transition-none motion-reduce:hover:transform-none group-hover:-translate-x-2 mr-2 h-4 w-4' /> Close
                                    </div> */}
                                </div>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>

    )
}